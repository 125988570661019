import React from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { AppNavBar } from "baseui/app-nav-bar";
import { ChevronRight, Delete, Overflow, Upload } from "baseui/icon";
import styled from "styled-components";
import { Routes, Route, useNavigate } from "react-router-dom";
import Customers from "./pages/Customers";
import Customer from "./pages/Customer";
import Videos from "./pages/Videos";
import NewVideo from "./pages/NewVideo";

const engine = new Styletron();

const Container = styled.div`
  max-width: 83%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const App = () => {
  const navigate = useNavigate();
  const [mainItems, setMainItems] = React.useState([
    { icon: ChevronRight, label: "Clients", active: false, to: "/customers" },
    {
      icon: Upload,
      label: "Stacks",
      navExitIcon: Delete,
      active: false,
      to: "/stacks",
    },
    {
      icon: Upload,
      label: "Videos",
      navExitIcon: Delete,
      active: false,
      to: "/videos",
    },
  ]);

  const setMainItemActive = (item: any) => {
    let newMainItems = [...mainItems];

    for (let i = 0; i < mainItems.length; i++) {
      if (mainItems[i] === item) {
        newMainItems[i].active = true;
        navigate(item.to);
      } else {
        newMainItems[i].active = false;
      }
    }

    setMainItems(newMainItems);
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <AppNavBar
          title={
            <div
              onClick={() => {
                navigate("/");
              }}
            >
              Momentum Admin
            </div>
          }
          mainItems={mainItems}
          onMainItemSelect={setMainItemActive}
          username="Momentum Pilates"
          userItems={[{ icon: Overflow, label: "Log Out" }]}
          onUserItemSelect={(item) => console.log(item)}
        />
        <Container>
          <Routes>
            <Route path="/customers" element={<Customers />} />
            <Route path="/customer/:id" element={<Customer />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/videos/new" element={<NewVideo />} />
          </Routes>
        </Container>
      </BaseProvider>
    </StyletronProvider>
  );
};

export default App;
