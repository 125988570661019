import { Auth } from "aws-amplify";

const httpRequest = async (
  url: string,
  method: string = "GET",
  body?: any,
  options?: any
) => {
  let defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
  } as any;

  // Apply access tokens where available
  try {
    const currentSession = await Auth.currentSession();
    defaultHeaders["Authorization"] = `Bearer ${currentSession
      .getIdToken()
      .getJwtToken()}`;
  } catch {
    // throws an exception when unauthenticated
  }

  return await fetch(url, {
    method: method,
    body: body ? JSON.stringify(body) : null,
    ...options,
    headers: {
      ...defaultHeaders,
      ...options?.headers,
    },
  });
};

export default httpRequest;
