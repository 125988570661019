import React, { useState } from "react";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { StatefulCheckbox } from "baseui/checkbox";
import styled from "styled-components";

const Player = styled.iframe`
  display: block;
  width: 30rem;
  height: 16.875rem;
  border-radius: 20px;
`;

const NewVideo = (props: any) => {
  const navigate = useNavigate();

  const [vimeoLink, setVimeoLink] = useState("");

  const [vimeoEmbedLink, setVimeoEmbedLink] = useState("");

  const updateVimeoLink = (link: string) => {
    setVimeoLink(link);

    if (link !== "" && link.match(/\/\d+$/gi)) {
      setVimeoEmbedLink(
        `https://player.vimeo.com/video${link.match(/\/\d+$/gi)}`
      );
    } else {
      setVimeoEmbedLink("");
    }
  };

  return (
    <>
      <h1>Create New Video</h1>

      <FormControl label="Title">
        <Input />
      </FormControl>

      <FormControl label="Vimeo Link">
        <Input
          value={vimeoLink}
          onChange={(e: any) => {
            updateVimeoLink(e.target.value);
          }}
        />
      </FormControl>

      {vimeoEmbedLink !== "" ? (
        <FormControl label="Vimeo Preview">
          <>
            <Player
              src={`${vimeoEmbedLink}?autoplay=0`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
            <small>
              <em>Embed link: {vimeoEmbedLink}</em>
            </small>
          </>
        </FormControl>
      ) : (
        <>{vimeoLink !== "" && <>Invalid Vimeo Link</>}</>
      )}

      <FormControl label="Description">
        <Textarea></Textarea>
      </FormControl>

      <FormControl label="Workout Levels">
        <>
          <StatefulCheckbox>Advanced</StatefulCheckbox>
          <StatefulCheckbox>Fundamentals</StatefulCheckbox>
          <StatefulCheckbox>Intermediate</StatefulCheckbox>
        </>
      </FormControl>

      <FormControl label="Body Areas">
        <>
          <StatefulCheckbox>General posture</StatefulCheckbox>
          <StatefulCheckbox>Hamstrings and calves</StatefulCheckbox>
          <StatefulCheckbox>Lower back</StatefulCheckbox>
          <StatefulCheckbox>Neck and shoulders</StatefulCheckbox>
          <StatefulCheckbox>Quads</StatefulCheckbox>
        </>
      </FormControl>

      <FormControl label="Activities">
        <>
          <StatefulCheckbox>Cycling</StatefulCheckbox>
          <StatefulCheckbox>Gardening</StatefulCheckbox>
          <StatefulCheckbox>Running</StatefulCheckbox>
          <StatefulCheckbox>Swimming</StatefulCheckbox>
          <StatefulCheckbox>Walking</StatefulCheckbox>
        </>
      </FormControl>

      <Button>Create</Button>
    </>
  );
};

export default NewVideo;
