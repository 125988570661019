import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import httpRequest from "../utils/httpRequest";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { Spinner } from "baseui/spinner";
import TableBuilderCell from "../components/TableBuilderCell";
import { Button } from "baseui/button";

const Customers = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([] as any[]);

  useEffect(() => {
    (async () => {
      const customerResponse = await httpRequest("/api/admin/clients/list");

      setCustomers(await customerResponse.json());
    })();
  }, []);

  return (
    <>
      {customers ? (
        <>
          <TableBuilder data={customers}>
            <TableBuilderColumn header="Name">
              {(row) => (
                <TableBuilderCell>
                  {row.firstName} {row.lastName}
                </TableBuilderCell>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn header="Email">
              {(row) => (
                <TableBuilderCell>
                  <a href={`mailto:${row.email}`}>{row.email}</a>
                </TableBuilderCell>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn>
              {(row) => (
                <TableBuilderCell align="right">
                  <Button
                    size="compact"
                    kind="secondary"
                    onClick={() => {
                      navigate(`/customer/${row.id}`);
                    }}
                  >
                    View
                  </Button>
                </TableBuilderCell>
              )}
            </TableBuilderColumn>
          </TableBuilder>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Customers;
