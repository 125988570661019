import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import httpRequest from "../utils/httpRequest";
import { Spinner } from "baseui/spinner";

import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";

const Customer = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null as any);

  useEffect(() => {
    (async () => {
      const customerResponse = await httpRequest(`/api/admin/clients/${id}`);

      setClient(await customerResponse.json());
    })();
  }, [id]);

  return (
    <>
      {client ? (
        <>
          <h1>
            {client.firstName} {client.lastName}
          </h1>
          <div>
            <FormControl label="First Name">
              <>
                <Input value={client.firstName} />
              </>
            </FormControl>
            <FormControl label="Last Name">
              <>
                <Input value={client.lastName} />
              </>
            </FormControl>
            <FormControl label="Email">
              <>
                <Input value={client.email} />
              </>
            </FormControl>

            {Object.keys(client.details).map((key: string) => {
              return (
                <FormControl label={key}>
                  <ul>
                    {client.details[key].map((detail: string) => {
                      return <li>{detail}</li>;
                    })}
                  </ul>
                </FormControl>
              );
            })}
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Customer;
