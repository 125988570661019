import React from "react";
import styled from "styled-components";

const Cell = styled.div`
  display: flex;

  &.align-right {
    justify-content: flex-end;
  }
`;

const TableBuilderCell = (props: any) => {
  return (
    <Cell className={`align-${props.align ?? "default"}`}>
      {props.children}
    </Cell>
  );
};

export default TableBuilderCell;
