import React from "react";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";

const Videos = (props: any) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        onClick={() => {
          navigate("/videos/new");
        }}
      >
        Create New
      </Button>{" "}
      <br />
      <br />
      (existing videos go here)
    </>
  );
};

export default Videos;
